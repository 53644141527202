import React from 'react';
import { useSelector } from 'react-redux';
import { Stack, Alert, Typography } from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";

import ProfileDetailsSkeleton from 'components/profile/ProfileDetailsSkeleton';
import ProfileDetails from 'components/profile/ProfileDetails';
import ProfileAdminPanel from "components/profile/ProfileAdminPanel";
import SubscriptionNotice from "components/profile/SubscriptionNotice";

const ProfileView = () => {
    const profile = useSelector(({ profile }) => profile.current);
    const me = useSelector((state) => state.profile.me);
    const isLoading = useSelector(({ profile }) => profile.isLoading);

    if (isLoading) {
        return <ProfileDetailsSkeleton />;
    }

    if (!profile) {
        return (
            <Alert
                startDecorator={<WarningIcon fontSize="xl4" color="danger" />}
                variant="soft"
                color="danger"
            >
                <div>
                    <Typography level="h3" color="danger">
                        User Not Found
                    </Typography>
                    <Typography level="body-md" variant="plain" color="danger">
                        Sorry, this is an unknown user.
                    </Typography>
                </div>
            </Alert>
        );
    }

    return (
        <Stack gap={2}>
            <SubscriptionNotice />
            <ProfileDetails profile={profile}/>
            <ProfileAdminPanel profile={profile} me={me} />
        </Stack>

    );
}

export default ProfileView;
