import React from 'react';
import SignUpForm from "components/core/SignUpForm";

const SignUpView = () => (
    <SignUpForm />
);

SignUpView.propTypes = {};

export default SignUpView;
