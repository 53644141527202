import {Notice} from "domain";
import appActions from "actions/appActions";
import { DateTime } from 'luxon';
import _ from 'lodash';

export default class CommonUtils {
    static EMPTY_FUNCTION = () => {};

    static generateKey = () => (Math.random() * 0xFFFFFF << 0).toString(16)

    static dispatcher = (action, dispatch, successMessage = 'Success', errorMessage = 'Error') => {
        return dispatch(action)
            .then(() => {
                const successMsg = (typeof successMessage === 'string') ?
                    { title: successMessage, type: Notice.TYPE_SUCCESS } :
                    { type: Notice.TYPE_SUCCESS, ...successMessage };
                dispatch(appActions.addNotice(new Notice(successMsg)));
            })
            .catch((error) => {
                const successMsg = (typeof errorMessage === 'string') ?
                    { title: errorMessage, details: error.details, type: Notice.TYPE_ERROR } :
                    { type: Notice.TYPE_ERROR, details: error.details, ...errorMessage };
                dispatch(appActions.addNotice(new Notice(successMsg)));
            });
    };

    static deepEqual = (object1, object2) => _.isEqual(object1, object2);

    static formatDate = (date) => {
        if (!date) return '';
        let dateTime;
        if (typeof date === 'string') {
            dateTime = DateTime.fromISO(date);
        } else if (date.iso) {
            dateTime = DateTime.fromISO(date.iso);
        } else if (date instanceof Date) {
            dateTime = DateTime.fromJSDate(date);
        }
        return dateTime ? dateTime.toLocaleString(DateTime.DATE_MED) : '';
    };

    static formatDateRange = (start, end) => {
        if (end && !CommonUtils.isSameDay(start, end)) {
            return `${CommonUtils.formatDate(start)} - ${CommonUtils.formatDate(end)}`;
        }
        return CommonUtils.formatDate(start);
    };

    static formatDateTime = (date) => {
        if (!date) return '';

        let dateTime;
        if (typeof date === 'string') {
            dateTime = DateTime.fromISO(date);
        } else if (date.iso) {
            dateTime = DateTime.fromISO(date.iso);
        } else if (date instanceof Date) {
            dateTime = DateTime.fromJSDate(date);
        }

        return dateTime ? dateTime.toFormat('LLL dd \'at\' h:mm a') : '';
    };

    static fromNow = (date, def = 'N/A') => {
        if (!date) return def;

        let dateStr;
        if (date.iso) {
            dateStr = date.iso;
        } else if (typeof date === 'string' || date instanceof DateTime) {
            dateStr = date;
        } else {
            return def;
        }

        const dateTime = DateTime.fromISO(dateStr);
        const now = DateTime.now();

        if (now.diff(dateTime, 'minutes').minutes < 1) {
            return "Now";
        }

        return dateTime.toRelative() ?? def;
    };

    static formatSizeUnits = (bytes) => {
        if (bytes >= 1073741824) { return `${Math.round(bytes / 1073741824)} GB`; }
        if (bytes >= 1048576) { return `${Math.round(bytes / 1048576)} MB`; }
        if (bytes >= 1024) { return `${Math.round(bytes / 1024)} KB`; }
        if (bytes > 1) { return `${bytes} bytes`; }
        if (bytes === 1) { return '1 byte'; }
        return '--';
    };

    static sanitizeFileName = (fileName) => {
        return fileName.replace(/[^a-zA-Z0-9.]/g, '_');
    };

    static isSameDay = (date1, date2) => {
        if (date1 == null || date2 == null) {
            return false;
        }

        const parseDate = (date) => {
            if (date.iso) {
                return DateTime.fromISO(date.iso);
            } else if (date instanceof Date) {
                return DateTime.fromJSDate(date);
            } else if (date instanceof DateTime) {
                return date;
            } else {
                return DateTime.fromISO(date);
            }
        };

        const dt1 = parseDate(date1);
        const dt2 = parseDate(date2);

        return dt1.hasSame(dt2, 'day');
    };

    static getInitials = (name) => {
        if (!name || typeof name !== 'string') return '';

        const cleanedName = name.replace(/[^a-zA-Z0-9\s]/g, '');

        const parts = cleanedName.trim().split(/\s+/);
        let initials = parts.map(part => part[0].toUpperCase());

        if (initials.length > 3) {
            initials = initials.slice(0, 3);
        }

        return initials.join('');
    }

    static isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    }
}
