import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Alert, Typography } from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";

import MaterialDetails from "components/material/MaterialDetails";
import MaterialDetailsSkeleton from "components/material/MaterialDetailsSkeleton";
import MaterialEditor from "components/material/MaterialEditor";

const MaterialView = () => {
    const material = useSelector(({ material }) => material.current);
    const isLoading = useSelector(({ material }) => material.isLoading);
    const course = useSelector(({ course }) => course.current);
    const [isEditing, setEditing] = useState(false);

    if (isLoading) {
        return <MaterialDetailsSkeleton />;
    }

    if (!material) {
        return (
            <Alert
                startDecorator={<WarningIcon fontSize="xl4" color="danger" />}
                variant="soft"
                color="danger"
            >
                <div>
                    <Typography level="h3" color="danger">
                        Material Not Found
                    </Typography>
                    <Typography level="body-md" variant="plain" color="danger">
                        Sorry, this is an unknown material.
                    </Typography>
                </div>
            </Alert>
        );
    }

    if (isEditing) {
        return <MaterialEditor material={material} course={course} onClose={() => setEditing(false)} />
    }

    return (
        <>
            <MaterialDetails material={material} course={course} onEdit={() => setEditing(true)} />
        </>
    );
};

export default MaterialView;
