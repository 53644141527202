export default class WatcherUtils {
    static WATCHERS = {};

    static watch(scope, key, functions, query) {
        const watcherKey = `${scope}_${key}`;

        if (WatcherUtils.WATCHERS[watcherKey]) {
            return Promise.reject(new Error(`Watcher for ${watcherKey} already exists.`));
        }

        return query.subscribe().then(subscriber => {
            WatcherUtils.WATCHERS[watcherKey] = subscriber;
            Object.keys(functions).forEach(event => {
                const func = functions[event];
                if (typeof func === 'function') {
                    subscriber.on(event, func);
                }
            });
            return subscriber;
        });
    }

    static unwatch(scope, key) {
        return new Promise((resolve, reject) => {
            const watcherKey = `${scope}_${key}`;
            const watcher = WatcherUtils.WATCHERS[watcherKey];

            if (watcher) {
                try {
                    watcher.unsubscribe();
                    delete WatcherUtils.WATCHERS[watcherKey];
                    resolve(true);
                } catch (error) {
                    reject(new Error('Error unsubscribing: ' + error.message));
                }
            } else {
                resolve(false);
            }
        });
    }

    static unwatchAll() {
        return new Promise((resolve, reject) => {
            try {
                for (const key in WatcherUtils.WATCHERS) {
                    if (WatcherUtils.WATCHERS.hasOwnProperty(key)) {
                        const watcher = WatcherUtils.WATCHERS[key];
                        if (watcher) {
                            watcher.unsubscribe();
                        }
                    }
                }
                WatcherUtils.WATCHERS = {};
                resolve();
            } catch (error) {
                reject(new Error('Error while unsubscribing all watchers: ' + error.message));
            }
        });
    }
}
