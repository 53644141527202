import { extendTheme } from '@mui/joy/styles';

const theme = extendTheme({
    colorSchemes: {
        light: {
            radius: {
                xs: "4px",
                sm: "8px",
                md: "10px",
                lg: "12px",
                xl: "16px",
            },
            palette: {
                "primary": {
                    "50": "#eff4f1",
                    "100": "#e0e9e4",
                    "200": "#d1ded7",
                    "300": "#c2d4ca",
                    "400": "#b3c9bd",
                    "500": "#a4beb0", // base
                    "600": "#95b4a3",
                    "700": "#86a996",
                    "800": "#69937c",
                    "900": "#5e8470"
                },
            }
        },
        dark: {
            radius: {
                xs: "4px",
                sm: "8px",
                md: "10px",
                lg: "12px",
                xl: "16px",
            },
            palette: {
                "primary": {
                    "50": "#eff4f1",
                    "100": "#e0e9e4",
                    "200": "#d1ded7",
                    "300": "#c2d4ca",
                    "400": "#b3c9bd",
                    "500": "#a4beb0",
                    "600": "#95b4a3",
                    "700": "#86a996",
                    "800": "#69937c",
                    "900": "#5e8470"
                },
            }
        }
    },
    components: {
        JoyChip: {
            defaultProps: {
                variant: "soft"
            },
            styleOverrides: {
                root: {
                    '--Chip-paddingInline': '24px',
                },
            },
        },
        JoyButton: {
            styleOverrides: {
                root: {
                    '--Button-radius': '24px',
                },
            },
        },
        JoyInput: {
            defaultProps: {
                color: "neutral",
                size: "lg",
                variant: "outlined",
            }
        },
        JoyTextarea: {
            defaultProps: {
                color: "neutral",
                size: "lg",
                variant: "outlined",
            }
        },
        JoySelect: {
            defaultProps: {
                color: "neutral",
                size: "lg",
                variant: "outlined",
            }
        },
        JoySheet: {
            defaultProps: {
                variant: "soft",
            },
            styleOverrides: {
                root: {
                    'borderRadius': 'var(--joy-radius-xs)',
                    'boxShadow': 'var(--joy-shadow-sm)',
                },
            },
        }
    },
})

export default theme;
