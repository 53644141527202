import React from 'react';
import { useSelector} from "react-redux";
import { Alert, Box, Stack, Container, Typography } from "@mui/joy";

import CoursesView from "views/course/CoursesView";
import ClassroomsViewStudent from "views/classroom/ClassroomsViewStudent";
import ClassroomsViewTutor from "views/classroom/ClassroomsViewTutor";
import ClassroomsViewAdmin from "views/classroom/ClassroomsViewAdmin";
import {Profile} from 'domain';

const ClassroomsView = () => {
    const isAuthorized = useSelector(({ auth }) => auth.isAuthorized);
    const profile = useSelector(({ profile }) => profile.me);

    if (!isAuthorized) {
        return <CoursesView />
    }

    switch (profile?.type) {
        case Profile.TYPE_STUDENT:
            return <ClassroomsViewStudent />;
        case Profile.TYPE_TUTOR:
            return <ClassroomsViewTutor />;
        case Profile.TYPE_ADMIN:
            return <ClassroomsViewAdmin />;
        default:
            return (
                <Container maxWidth="md">
                    <Box sx={{my: 4}}>
                        <Alert variant="soft" color="danger">
                            <Stack width="100%">
                                <Typography level="h3" color="danger">
                                    Unknown User Type
                                </Typography>
                                <Typography level="body-md" variant="plain" color="danger">
                                    Sorry, something went wrong. Please contact the system administrator.
                                </Typography>
                            </Stack>
                        </Alert>
                    </Box>
                </Container>
            );
    }
}

ClassroomsView.propTypes = {};

export default ClassroomsView;
