import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Alert, Typography} from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";

import CourseDetails from "components/course/CourseDetails";
import CourseEditor from "components/course/CourseEditor";
import ProfileDetailsSkeleton from "components/profile/ProfileDetailsSkeleton";
import enrollmentRequestActions from "actions/enrollmentRequestActions";
import {Profile} from "domain";

const CourseView = () => {
    const dispatch = useDispatch();
    const course = useSelector(({ course }) => course.current);
    const sections = useSelector(({ section }) => section.list);
    const sectionsCourseId = useSelector(({ section }) => section.listCourseId);
    const isLoading = useSelector(({ course }) => course.isLoading);
    const me = useSelector(({ profile }) => profile.me);
    const [isEditing, setEditing] = useState(false);

    useEffect(() => {
        if (me && me.type !== Profile.TYPE_STUDENT && course && course.id) {
            dispatch(enrollmentRequestActions.listByCourse(course))
        }
    }, [course, me])

    useEffect(() => {
        return () => {
            dispatch(enrollmentRequestActions.clear())
        }
    }, [])

    if (isLoading) {
        return <ProfileDetailsSkeleton />;
    }

    if (!course) {
        return (
            <Alert
                startDecorator={<WarningIcon fontSize="xl4" color="danger" />}
                variant="soft"
                color="danger"
            >
                <div>
                    <Typography level="h3" color="danger">
                        Course Not Found
                    </Typography>
                    <Typography level="body-md" variant="plain" color="danger">
                        Sorry, this is an unknown course.
                    </Typography>
                </div>
            </Alert>
        );
    }

    if (isEditing) {
        return <CourseEditor course={course} onClose={() => setEditing(false)} />;
    }

    return (
        <CourseDetails
            course={course}
            sections={sectionsCourseId === course.id ? sections : null}
            onEdit={() => setEditing(true)}
            onEnroll={() => {}}
        />
    );
}

export default CourseView;
