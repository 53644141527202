import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import {useNavigate} from "react-router-dom";
import { Avatar, Box, Button, Typography } from "@mui/joy";
import EmailIcon from "@mui/icons-material/Email";

// Replace with actual import
import ForgotPasswordForm from "components/core/ForgotPasswordForm";
import OverlayModal from "components/core/OverlayModal";
import authActions from "actions/authActions";
import appActions from "actions/appActions";
import {Notice} from "domain";

const ForgotPasswordView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const handleChange = (evt, val) => {
        setEmail(val ?? evt.target.value)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        dispatch(authActions.reset(email)).then(() => {
            dispatch(appActions.addNotice(new Notice({
                title: 'Reset Link Sent',
                details: 'Please check your email, and use the provided link to reset your password.',
                type: Notice.TYPE_SUCCESS
            }))).then(() => {
                setEmail('');
                navigate('/classroom');
            });
        })

        // Add logic for forgot password action
        // dispatch(forgotPasswordActions.requestReset(email));

        // Clear the email field and set a success message if needed

    };

    return (
        <OverlayModal>
            <Avatar sx={{ mt: 2 }} color="primary">
                <EmailIcon />
            </Avatar>
            <Typography level="title-lg">Forgot Password</Typography>
            <Typography level="body-sm" textAlign="left" my={1}>
                Enter your email address and we will send you a link to reset your password.
            </Typography>
            <Box my={2} width="100%">
                <ForgotPasswordForm email={email} onChange={handleChange} onReset={handleSubmit} />
            </Box>
            <Button
                type="submit"
                fullWidth
                variant="solid"
                onClick={handleSubmit}
                disabled={!email}
            >
                Send Reset Link
            </Button>
        </OverlayModal>
    );
}

export default ForgotPasswordView;
