import React from 'react';
import { useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import DOMPurify from "dompurify";
import {marked} from "marked";
import {
    Accordion,
    AccordionSummary,
    Box,
    AccordionDetails,
    AccordionGroup,
    Stack,
    Typography, Sheet
} from "@mui/joy";

import ProcessingOverlay from "components/core/ProcessingOverlay";
import Header from "components/core/Header";
import LanguageLabel from "components/core/LanguageLabel";
import ProfileChip from "components/core/ProfileChip";
import {Profile} from "domain";
import SectionList from "components/section/SectionList";

const ClassroomView = () => {
    const navigate = useNavigate();
    const course = useSelector(({ course }) => course.current);
    const isCourseLoading = useSelector(({ course }) => course.isLoading);
    const classroom = useSelector(({ classroom }) => classroom.current);
    const isClassroomLoading = useSelector(({ classroom }) => classroom.isLoading);
    const sections = useSelector(({ section }) => section.list);

    const handleSelectSection = (section) => {
        navigate(`/classroom/${course.id}/${classroom.id}/${section.id}`)
    }

    if (!course) {
        return <ProcessingOverlay key="loading-overlay" enabled />
    }

    const tutorProfile = new Profile(course.get('tutor')?.get('profile'));
    const descriptionHtml = DOMPurify.sanitize(marked.parse(course.description ?? '', { gfm: true, breaks: true}));

    return (
        <Stack gap={4}>
            <ProcessingOverlay key="loading-overlay" enabled={isClassroomLoading || isCourseLoading} />
            {sections && (
                <Stack>
                    <Typography level="h3" color="neutral">Select Section</Typography>
                    <SectionList
                        course={course}
                        sections={sections}
                        onClick={handleSelectSection}
                    />
                </Stack>
            )}
            <AccordionGroup  transition="0.3s ease" variant="plain">
                <Accordion>
                    <AccordionSummary variant="soft" sx={{ p: 2 }}>Course Overview</AccordionSummary>
                    <AccordionDetails>
                        <Stack gap={1} sx={{ p: 1 }}>
                            <Header>
                                {course.name}
                            </Header>
                            <Box display="flex" gap={1} justifyContent="space-between">
                                <LanguageLabel language={course.language} />
                                <ProfileChip profile={tutorProfile} disabled />
                            </Box>
                            <Typography level="body-md" color="neutral">{course.tagline}</Typography>
                            <Sheet variant="soft" sx={{ px: 2 }}>
                                <div dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
                            </Sheet>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </AccordionGroup>

        </Stack>
    );
}

ClassroomView.propTypes = {};

export default ClassroomView;
