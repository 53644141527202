import React, { useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Alert, Typography} from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";

import messageActions from "actions/messageActions";
import MessageDetails from "components/message/MessageDetails";
import MessageDetailsSkeleton from "components/message/MessageDetailsSkeleton";
import { Message } from 'domain';

const MessageView = () => {
    const dispatch = useDispatch();
    const message = useSelector(({ message }) => message.current);
    const isLoading = useSelector(({ message }) => message.isLoading);

    useEffect(() => {
        if (message?.id && message.status === Message.STATUS_UNREAD) {
            dispatch(messageActions.markAsRead(message.id))
        }
    }, [message])

    if (isLoading) {
        return <MessageDetailsSkeleton />;
    }

    if (!message) {
        return (
            <Alert
                startDecorator={<WarningIcon fontSize="xl4" color="danger" />}
                variant="soft"
                color="danger"
            >
                <div>
                    <Typography level="h3" color="danger">
                        Message Not Found
                    </Typography>
                    <Typography level="body-md" variant="plain" color="danger">
                        Sorry, this is an unknown message.
                    </Typography>
                </div>
            </Alert>
        );
    }

    return (
        <MessageDetails message={message}/>
    );
}

export default MessageView;
