import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import messageActions from "actions/messageActions";
import Header from "components/core/Header";
import MessageTable from "components/message/MessageTable";

const InboxView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const messages = useSelector(({ message }) => message.list);
    const me = useSelector(({ profile }) => profile.me);

    useEffect(() => {
        dispatch(messageActions.list());
        return () => dispatch(messageActions.delist());
    }, []);

    return (
        <>
            <Header>Message Inbox</Header>
            <MessageTable messages={messages} me={me} onClick={(msg) => {
                navigate(`/message/${msg.id}`)
            }}/>
        </>
    );
}

InboxView.propTypes = {};

export default InboxView;
