import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useNavigate} from "react-router-dom";
import { Button, Stack, Card, CardOverflow, AspectRatio, Container, CardContent, CardActions, Typography} from "@mui/joy";
import ClassroomIcon from "@mui/icons-material/School";
import Add from '@mui/icons-material/Add';

import courseActions from "actions/courseActions";
import classroomActions from "actions/classroomActions";
import Header from "components/core/Header";
import ClassroomList from "components/classroom/ClassroomList";
import CourseTable from "components/course/CourseTable";
import ProcessingOverlay from "components/core/ProcessingOverlay";
import {Profile} from "domain";
import SubscriptionNotice from "components/profile/SubscriptionNotice";

const ClassroomsViewTutor = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthorized = useSelector(({ auth }) => auth.isAuthorized);
    const me = useSelector(({ auth }) => auth.me);
    const myProfile = useSelector(({ profile }) => profile.me);
    const courses = useSelector(({ course }) => course.list);
    const isListLoading = useSelector(({ course }) => course.isListLoading);
    const classrooms = useSelector(({ classroom }) => classroom.list);
    const classroomListId = useSelector(({ classroom }) => classroom.listId);
    const isClassroomListLoading = useSelector(({ classroom }) => classroom.isListLoading);
    const [course, setCourse] = useState(null);

    let content = null;

    const handleClick = (nextCourse) => {
        if (course && course.id === nextCourse.id) {
            setCourse(null)
        } else {
            setCourse(nextCourse)
        }

    }

    const handleNew = () => {
        navigate('/course/new')
    }

    const handleOpenSettings = () => {
        navigate(`/profile/${me.get('profile')?.id}`)
    }

    const handleOpen = (classroom) => {
        navigate(`/classroom/${classroom.course.id}/${classroom.id}`)
    }

    useEffect(()=> {
        if (me && !isListLoading) {
            dispatch(courseActions.findByTutor(me))
        }
    }, [me])

    useEffect(()=> {
        if (course && classroomListId !== course.id) {
            dispatch(classroomActions.findByCourse(course))
        } else {
            dispatch(classroomActions.findByTutor(me))
        }

        return () => {
            dispatch(classroomActions.clear())
        }
    }, [course])

    useEffect(()=> {
        return () => {
            dispatch(courseActions.clear())
        }
    }, [])

    if (myProfile.billingStatus === Profile.BILLING_STATUS_DEACTIVATED) {
        content = null;
    } else if (courses.length === 0 && !isListLoading) {
        content = (
            <Container maxWidth="sm">
                <ProcessingOverlay key="classes-loading-overlay" enabled={isListLoading} />
                <Card
                    data-resizable
                    sx={{
                        textAlign: 'center',
                        alignItems: 'center',
                        overflow: 'auto',
                        '--icon-size': '100px',
                    }}
                >
                    <CardOverflow variant="soft" color="primary">
                        <AspectRatio
                            variant="outlined"
                            color="primary"
                            ratio="1"
                            sx={{
                                m: 'auto',
                                transform: 'translateY(50%)',
                                borderRadius: '50%',
                                width: 'var(--icon-size)',
                                boxShadow: 'sm',
                                bgcolor: 'background.surface',
                                position: 'relative',
                            }}
                        >
                            <div>
                                <ClassroomIcon color="primary" sx={{ fontSize: '4rem' }} />
                            </div>
                        </AspectRatio>
                    </CardOverflow>
                    <Typography level="title-lg" sx={{ mt: 'calc(var(--icon-size) / 2)' }}>
                        You Have No Courses
                    </Typography>
                    <CardContent sx={{ px:4, py: 2}}>
                        To get started, create a course, add sections &amp; materials to it, when begin
                        enrolling students.
                    </CardContent>
                    <CardActions
                        orientation="vertical"
                        buttonFlex={1}
                    >
                        <Button variant="solid" color="primary" startDecorator={< Add />} size="lg" onClick={handleNew}>
                            Create Your First Course
                        </Button>
                        <Button variant="plain" color="neutral" onClick={handleOpenSettings}>
                            Your Profile Settings
                        </Button>
                    </CardActions>
                </Card>
            </Container>
        )
    } else {
        content = (
            <Stack>
                <Header>
                    Your Courses
                    {isAuthorized && me?.type !== Profile.TYPE_STUDENT && (
                        <Button sx={{ height: "fit-content" }} onClick={handleNew}>Create Course</Button>
                    )}
                </Header>
                <CourseTable
                    courses={courses}
                    onClick={handleClick}
                    perspective={Profile.TYPE_TUTOR}
                    selected={course}
                    hideTutor
                />
                {classrooms.length > 0 && (
                    <Stack sx={{ mt: 4 }}>
                        <Typography level="title-lg" color="neutral" gutterBottom>Classrooms</Typography>
                        <ClassroomList classrooms={classrooms} perspective={Profile.TYPE_TUTOR} onClick={handleOpen}/>
                    </Stack>
                )}
            </Stack>
        )
    }

    return (
        <>
            <ProcessingOverlay key="classes-loading-overlay" enabled={isListLoading || isClassroomListLoading} />
            <SubscriptionNotice />
            {content}
        </>
    );
}

export default ClassroomsViewTutor;
