/**
 * Function to compare two items in descending order.
 * @param {Object} a - The first item to compare.
 * @param {Object} b - The second item to compare.
 * @param {string} orderBy - The field to order by.
 * @returns {number} - A negative number if 'a' should come before 'b', a positive number if 'a' should come after 'b',
 *                     or 0 if they are equal.
 */
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

/**
 * Function to get the comparator function for sorting.
 * @param {string} order - The order direction ('asc' or 'desc').
 * @param {string} orderBy - The field to order by.
 * @returns {function} - A comparator function.
 */
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Function to sort an array in a stable manner.
 * @param {Array} array - The array to sort.
 * @param {function} comparator - The comparator function for sorting.
 * @returns {Array} - The sorted array.
 */
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export { descendingComparator, getComparator, stableSort };
