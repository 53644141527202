import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useLocation } from 'react-router-dom';
import {Box, Button, Sheet, Chip, Typography} from "@mui/joy";
import SubscriptionIcon from "@mui/icons-material/CardMembership";
import { loadStripe } from '@stripe/stripe-js';

import Config from 'Config';

import CommonUtils from "utils/CommonUtils";
import subscriptionActions from "actions/subscriptionActions";
import {Profile} from "domain";

// import imgSubscription from 'img/subscriptions.svg';
const { stripePublicKey } = Config;
const stripePromise = loadStripe(stripePublicKey);

const SubscriptionView = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const me = useSelector(({ profile }) => profile.me);
    const [isSending, setIsSending] = useState(false);

    let billingColor = 'danger';

    if (me.billingStatus === Profile.BILLING_STATUS_ACTIVE) {
        billingColor = 'primary'
    } else if (me.billingStatus === Profile.BILLING_STATUS_CANCELED) {
        billingColor = 'warning'
    }


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const sessionId = params.get('sid');

        if (sessionId && me.billingStatus === Profile.STATUS_INACTIVE) {
            setIsSending(true);
            dispatch(subscriptionActions.update(sessionId)).then(() => {
                setIsSending(false);
            })
        }
    }, [location]);

    const handleSubscribe = async () => {
        setIsSending(true);
        try {
            const { value: sessionId } = await dispatch(subscriptionActions.subscribe());
            const stripe = await stripePromise;
            await stripe.redirectToCheckout({ sessionId });
        } catch (error) {
            console.error('Error redirecting to Stripe Checkout:', error);
            setIsSending(false);
        }
    }

    const handleBillingPortal = async () => {
        setIsSending(true);
        try {
            const { value } = await dispatch(subscriptionActions.createBillingPortalSession());
            window.location.href = value;
        } catch (error) {
            console.error('Error redirecting to Stripe billing portal:', error);
            setIsSending(false);
        }
    };

    return (
        <>
            <Sheet sx={{ p: 2, mb: 2 }} variant="outlined" component="div">
                <Box
                    display="flex"
                    flexDirection={{xs: 'column', sm: 'row'}}
                    gap={2}
                    alignItems={{xs: 'center', sm: 'flex-start'}}
                    justifyContent={'space-between'}
                >
                    <Typography level="h3">
                        Manage Subscription
                    </Typography>
                    <Box align="right">
                        <Typography level="body-sm" component="div">
                            {'Billing Status: '}
                            <Chip
                                color={billingColor}
                                variant="solid"
                                size="sm"
                            >
                                {Profile.BILLING_STATUS_LABELS[me.billingStatus]}
                            </Chip>
                        </Typography>
                        {(me.billingStatus === Profile.BILLING_STATUS_DEACTIVATED
                            || me.billingStatus === Profile.BILLING_STATUS_CANCELED) ? (
                                <Typography level="body-xs" component="div" color="neutral">
                                    {`Deactivation Date: ${CommonUtils.formatDate(me.deactivatedAt)}`}
                                </Typography>
                            ) : null}
                    </Box>
                </Box>

                <Box mt={2} component="div">

                    {me.billingStatus === Profile.STATUS_INACTIVE ? (
                        <Box>
                            <Typography level="body-md">
                                OFALL partners with Stripe for simplified and secure billing.
                            </Typography>

                            <Typography level="title-lg" color="primary" align="center">
                                Activate Your Account Now
                            </Typography>

                            <Typography level="title-md" align="center">
                                30 Day Free Trial
                            </Typography>

                            <Typography level="body-md" align="center">
                                Then $20.00 per month
                            </Typography>
                        </Box>
                    ) : (
                        <Typography level="body-md">
                            OFALL partners with Stripe for simplified and secure billing. To manage your subscription
                            and/or billing information, please use the billing portal:
                        </Typography>
                    )}


                    <Box mt={1} align="center" component="div">
                        {me.billingStatus === Profile.STATUS_INACTIVE ? (
                            <Button
                                startDecorator={<SubscriptionIcon />}
                                loading={isSending}
                                loadingIndicator="Loading..."
                                color="primary"
                                variant="solid"
                                size="lg"
                                onClick={handleSubscribe}
                            >
                            Activate Subscription
                            </Button>
                        ) : (
                            <Button
                                startDecorator={<SubscriptionIcon />}
                                loading={isSending}
                                loadingIndicator="Loading..."
                                color="primary"
                                variant="solid"
                                size="lg"
                                onClick={handleBillingPortal}
                            >
                            Billing Portal
                            </Button>
                        )}

                        <Box mt={2}>
                            <img src="img/stripe-badge.svg" alt="Powered by Stripe" style={{ width: '100%', maxWidth: 120 }} />
                        </Box>
                    </Box>
                </Box>
            </Sheet>

            <Box mt={8} align="center" component="div">
                <img src="img/subscriptions.svg" alt="Subscription Portal" style={{ width: '100%', maxWidth: 580 }} />
            </Box>
        </>
    );
}

SubscriptionView.propTypes = {};

export default SubscriptionView;
