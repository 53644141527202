import { Notice } from 'domain';

export default class ErrorHandlerUtils {
    static isError = ({ type }) => (type.includes('_REJECTED') || type.includes('ERROR_'));

    static statusCodeName = (code, fallback) => {
        switch (code) {
            case 400:
            case 406:
                return 'Bad Connection';

            case 401:
            case 403:
                return 'Login Session Expire or Invalid';

            case 404:
                return 'Object Not Found';

            case 500:
                return 'Server Error';

            case 502:
            case 503:
            case 504:
                return 'Server Unavailable';

            default:
                return fallback;
        }
    };

    static generateNotices = ({ type, payload }, state) => {
        const notices = state.notices.clone();
        let title;
        let details = payload.details ?? payload?.response?.data?.error;

        switch (type) {
            case 'LOGIN_USER_REJECTED':
                title = 'Login Failed';
                details = payload.message;
                break;
            case 'ADD_USER_REJECTED':
                title = 'Registration Failed';
                details = payload.message;
                break;
            case 'RESET_USER_REJECTED':
                title = 'Reset Failed';
                details = payload.message;
                break;
            default:
                title = ErrorHandlerUtils.statusCodeName(payload.response?.status, payload.message ?? 'Unknown Error');
        }

        notices.add(new Notice({
            title, details, source: payload, type: Notice.TYPE_ERROR, active: true,
        }));

        return notices;
    };

    static process = (state, action) => ({ ...state, notices: ErrorHandlerUtils.generateNotices(action, state) });
}
