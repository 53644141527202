import React from 'react';
import { Container, Box, Alert, Typography, Link } from '@mui/joy';
import WarningIcon from '@mui/icons-material/Warning';

const NotFoundView = () => (
    <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
            <Alert
                startDecorator={<WarningIcon fontSize="xl4" color="warning" />}
                variant="soft"
                color="warning"
            >
                <div>
                    <Typography level="h3" color="warning">
                        Page Not Found
                    </Typography>
                    <Typography level="body-md" variant="plain" color="warning">
                        Sorry, this is an unknown page.
                    </Typography>
                </div>
            </Alert>
        </Box>
        <Link
            href="/"
            color="warning"
            disabled={false}
            level="body-md"
            underline="none"
            variant="plain"
        >
            Return Home
        </Link>
    </Container>
);

NotFoundView.propTypes = {};

export default NotFoundView;
