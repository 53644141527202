import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import {Alert, Box, Button, Container, Stack, Typography} from "@mui/joy";
import ClassroomIcon from "@mui/icons-material/School";
import classroomActions from "actions/classroomActions";
import ProcessingOverlay from "components/core/ProcessingOverlay";
import ClassroomList from "components/classroom/ClassroomList";
import {Profile} from 'domain';

const ClassroomsViewStudent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classrooms = useSelector(({ classroom }) => classroom.list);
    const listId = useSelector(({ classroom }) => classroom.listId);
    const isListLoading = useSelector(({ classroom }) => classroom.isListLoading);
    const me = useSelector(({ auth }) => auth.me);

    const handleOpen = (classroom) => {
        navigate(`/classroom/${classroom.course.id}/${classroom.id}`)
    }

    useEffect(()=> {
        if (me && me.id !== listId && !isListLoading) {
            dispatch(classroomActions.findByStudent(me))
        }
    }, [me])

    useEffect(()=> {
        return () => {
            dispatch(classroomActions.clear())
        }
    }, [])

    if (classrooms.length === 0) {
        return (
            <Container maxWidth="md">
                <ProcessingOverlay key="classes-loading-overlay" enabled={isListLoading} />
                <Box sx={{my: 4}}>
                    <Alert
                        startDecorator={<ClassroomIcon fontSize="xl4" color="primary"/>}
                        variant="soft"
                        color="primary"
                        sx={{alignItems:"start"}}
                    >
                        <Stack width="100%">
                            <Typography level="h3" color="primary">
                                No Classrooms
                            </Typography>
                            <Typography level="body-md" variant="plain" color="primary">
                                Sorry, you&apos;re currently not sign up for any classes.
                            </Typography>
                            <Typography level="body-md" variant="plain" color="primary">
                                To get started, search our robust course catalog and sign up for your first class.
                            </Typography>
                            <Box py={2} align="center" onClick={() => { navigate('/course')}}>
                                <Button variant="solid" color="primary">
                                    Course Catalog
                                </Button>
                            </Box>
                        </Stack>
                    </Alert>
                </Box>
            </Container>
        );
    }

    return (
        <>
            <ProcessingOverlay key="classes-loading-overlay" enabled={isListLoading} />
            <ClassroomList classrooms={classrooms} perspective={Profile.TYPE_STUDENT} onClick={handleOpen} />
        </>
    );
}

export default ClassroomsViewStudent;
