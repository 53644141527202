import React from 'react';
import {useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from "@mui/joy";

import courseActions from "actions/courseActions";
import CourseFilter from "components/course/CourseFilter";
import Header from "components/core/Header";
import CourseTable from "components/course/CourseTable";
import { Profile } from 'domain';

const CoursesView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthorized = useSelector(({ auth }) => auth.isAuthorized);
    const me = useSelector(({ profile }) => profile.me);
    const courses = useSelector(({ course }) => course.list);

    const handleFind = ({ text, language, status }) => {
        dispatch(courseActions.find({ text, language, status }))
    }

    const handleClick = (course) => {
        navigate(`/course/${course.id}`)
    }

    return (
        <>
            <Header>
                Find Courses
                {isAuthorized && me?.type !== Profile.TYPE_STUDENT && (
                    <Button sx={{ height: "fit-content" }}  component={RouterLink} to="/course/new">Create Course</Button>
                )}
            </Header>
            <CourseFilter onChange={handleFind}/>
            <CourseTable courses={courses} onClick={handleClick} />
        </>
    );
}

export default CoursesView;
