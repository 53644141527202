import * as React from 'react';
import {Box, Typography, Card, CardContent, Grid, Alert, Stack} from '@mui/joy';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import CourseIcon from "@mui/icons-material/Class";
import UsersIcon from "@mui/icons-material/People";
import AnalyticsIcon from "@mui/icons-material/Analytics";

function ClassroomsViewAdmin() {
    const sections = [
        {
            title: 'User',
            content: (
                <Alert variant="soft" color="warning" sx={{ my: 4 }}>
                    <Stack width="100%">
                        <Typography level="h3" color="warning">
                            Coming Soon
                        </Typography>
                        <Typography level="body-md" variant="plain" color="warning">
                            This section has not been implemented yet.
                        </Typography>
                    </Stack>
                </Alert>
            ),
            Icon: UsersIcon
        },
        {
            title: 'Courses',
            content: (
                <Alert variant="soft" color="warning" sx={{ my: 4 }}>
                    <Stack width="100%">
                        <Typography level="h3" color="warning">
                            Coming Soon
                        </Typography>
                        <Typography level="body-md" variant="plain" color="warning">
                            This section has not been implemented yet.
                        </Typography>
                    </Stack>
                </Alert>
            ),
            Icon: CourseIcon
        },
        {
            title: 'Messages',
            content: (
                <Alert variant="soft" color="warning" sx={{ my: 4 }}>
                    <Stack width="100%">
                        <Typography level="h3" color="warning">
                            Coming Soon
                        </Typography>
                        <Typography level="body-md" variant="plain" color="warning">
                            This section has not been implemented yet.
                        </Typography>
                    </Stack>
                </Alert>
            ),
            Icon: QuestionAnswerRoundedIcon
        },
        {
            title: 'Reports',
            content: (
                <Alert variant="soft" color="warning" sx={{ my: 4 }}>
                    <Stack width="100%">
                        <Typography level="h3" color="warning">
                            Coming Soon
                        </Typography>
                        <Typography level="body-md" variant="plain" color="warning">
                            This section has not been implemented yet.
                        </Typography>
                    </Stack>
                </Alert>
            ),
            Icon: AnalyticsIcon
        },
    ];

    return (
        <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            sx={{ flexGrow: 1 }}
        >
            {sections.map((section, index) => (
                <Grid key={index} xs={12} sm={6}>
                    <Card variant="outlined" sx={{height: '100%'}}>
                        <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <section.Icon />
                                <Typography level="h6" component="h2">
                                    {section.title}
                                </Typography>
                            </Box>
                            <Typography component="div" height="100%">{section.content}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}

export default ClassroomsViewAdmin;
