import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {Alert, Typography} from "@mui/joy";
import WarningIcon from "@mui/icons-material/Warning";

import SectionDetails from "components/section/SectionDetails";
import SectionDetailsSkeleton from "components/section/SectionDetailsSkeleton";
import SectionEditor from "components/section/SectionEditor";
import {useNavigate, useParams} from "react-router-dom";
import {Profile} from "domain";

const SectionView = () => {
    const { courseId, sectionId, classroomId } = useParams();
    const navigate = useNavigate();
    const me = useSelector(({ profile }) => profile.me);
    const section = useSelector(({ section }) => section.current);
    const course = useSelector(({ course }) => course.current);
    const isLoading = useSelector(({ section }) => section.isLoading);
    const materials = useSelector(({ material }) => material.list);
    const materialsSectionId = useSelector(({ material }) => material.listSectionId);

    const tutorProfile = course ? new Profile(course.get('tutor')?.get('profile')) : null;
    const isEditable = me?.type === Profile.TYPE_ADMIN || me?.id === tutorProfile?.id;
    const [isEditing, setEditing] = useState(false);

    const handleOpen = classroomId
        ? (material) => {
            navigate(`/classroom/${courseId}/${classroomId}/${sectionId}/${material.id}`)
        } : isEditable ? (material) => {navigate(`/course/${course?.id}/section/${section.id}/${material.id}`)} : null;

    if (isLoading) {
        return <SectionDetailsSkeleton />;
    }

    if (!section) {
        return (
            <Alert
                startDecorator={<WarningIcon fontSize="xl4" color="danger" />}
                variant="soft"
                color="danger"
            >
                <div>
                    <Typography level="h3" color="danger">
                        Section Not Found
                    </Typography>
                    <Typography level="body-md" variant="plain" color="danger">
                        Sorry, this is an unknown section.
                    </Typography>
                </div>
            </Alert>
        );
    }

    if (isEditing) {
        return <SectionEditor section={section} onClose={() => setEditing(false)} />
    }

    return (
        <>
            <SectionDetails
                section={section}
                course={course}
                materials={materialsSectionId === section.id ? materials : null}
                onEdit={isEditable ? () => setEditing(true) : null}
                onOpen={handleOpen}
            />
        </>
    );
}

SectionView.propTypes = {};

export default SectionView;
